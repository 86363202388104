/* Buttons */
.btn-outline {
  background-color: transparent;
  color: inherit;
  transition: all .5s;
}

.btn-primary.btn-outline {
  color: $brand-primary;
}

.btn-success.btn-outline {
  color: $brand-success;
}

.btn-info.btn-outline {
  color: $brand-info;
}

.btn-warning.btn-outline {
  color: $brand-warning;
}

.btn-danger.btn-outline {
  color: $brand-danger;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
  color: #fff;
}

.btn-primary.btn-outline:hover {
  background: $brand-primary;
}
.btn-success.btn-outline:hover {
  background: $brand-success;
}
.btn-info.btn-outline:hover {
  background: $brand-info;
}
.btn-warning.btn-outline:hover {
  background: $brand-warning;
}
.btn-danger.btn-outline:hover {
  background: $brand-danger;
}