// Brands
$brand-primary: #ff0107;
$brand-secondary: #004a97;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #fde97c;
$brand-danger:  #d02020;

// Borders
// $laravel-border-color: darken($brand-primary, 15%);
// $list-group-border: $laravel-border-color;
// $navbar-default-border: $laravel-border-color;
// $panel-default-border: $laravel-border-color;
// $panel-inner-border: $laravel-border-color;
// $border-radius-base:        0px !default;
// $border-radius-large:       0px !default;
// $border-radius-small:       0px !default;

// Typography
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #5a6d80;

// Navbar
//$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

$btn-warning-color: darken($brand-warning, 38%);
$btn-warning-border: darken($brand-warning, 28%);

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;


$alert-info-bg:               $brand-info;
$alert-info-text:             #fff;
$alert-info-border:           darken($brand-info, 15%);