/* ==========================================================================
   fonts
========================================================================== */
@font-face {
  font-family: 'KievitOT-Regular';
  src: url('../fonts/KievitOT-Regular/KievitOT-Regular.eot?#iefix') format('embedded-opentype'),
     url('../fonts/KievitOT-Regular/KievitOT-Regular.otf')  format('opentype'),
     url('../fonts/KievitOT-Regular/KievitOT-Regular.woff') format('woff'),
     url('../fonts/KievitOT-Regular/KievitOT-Regular.ttf')  format('truetype'),
     url('../fonts/KievitOT-Regular/KievitOT-Regular.svg#KievitOT-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KievitOT-Bold';
  src: url('../fonts/KievitOT-Bold/KievitOT-Bold.eot?#iefix') format('embedded-opentype'),
     url('../fonts/KievitOT-Bold/KievitOT-Bold.otf')  format('opentype'),
       url('../fonts/KievitOT-Bold/KievitOT-Bold.woff') format('woff'),
       url('../fonts/KievitOT-Bold/KievitOT-Bold.ttf')  format('truetype'),
       url('../fonts/KievitOT-Bold/KievitOT-Bold.svg#KievitOT-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KievitOT-ExtraBold';
  src: url('../fonts/KievitOT-ExtraBold/KievitOT-ExtraBold.eot?#iefix') format('embedded-opentype'),
     url('../fonts/KievitOT-ExtraBold/KievitOT-ExtraBold.otf')  format('opentype'),
       url('../fonts/KievitOT-ExtraBold/KievitOT-ExtraBold.woff') format('woff'),
       url('../fonts/KievitOT-ExtraBold/KievitOT-ExtraBold.ttf')  format('truetype'),
       url('../fonts/KievitOT-ExtraBold/KievitOT-ExtraBold.svg#KievitOT-ExtraBold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KievitOT-ExtraLightItalic';
  src: url('../fonts/KievitOT-ExtraLightItalic/KievitOT-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
     url('../fonts/KievitOT-ExtraLightItalic/KievitOT-ExtraLightItalic.otf')  format('opentype'),
       url('../fonts/KievitOT-ExtraLightItalic/KievitOT-ExtraLightItalic.woff') format('woff'),
       url('../fonts/KievitOT-ExtraLightItalic/KievitOT-ExtraLightItalic.ttf')  format('truetype'),
       url('../fonts/KievitOT-ExtraLightItalic/KievitOT-ExtraLightItalic.svg#KievitOT-ExtraLightItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* ==========================================================================
   utils
========================================================================== */
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}

.mb-40 {
  margin-bottom: 40px;
}
.mb-20 {
  margin-bottom: 20px;
}


/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}

/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.clearfix {
    *zoom: 1;
}

/* ==========================================================================
   fix
========================================================================== */
.btn-outline {
    background-color: transparent;
    color: inherit;
    transition: all .5s;
}

.btn-primary.btn-outline {
    color: $brand-primary;
}

.btn-success.btn-outline {
    color: $brand-success;
}

.btn-info.btn-outline {
    color: $brand-info;
}

.btn-warning.btn-outline {
    color: $brand-warning;
}

.btn-danger.btn-outline {
    color: $brand-danger;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
    color: #fff;
}
.navbar-brand {
  padding: 5px 15px !important;
  height: 50px;

  img {
    max-height: 100%;
  }
}
.btn {
  font-family: 'KievitOT-Bold' !important;
}
h1,
h2 {
  font-family: 'KievitOT-Bold' !important;
}
strong,
.strong,
b,
.b {
  font-family: 'KievitOT-Bold' !important;
}
.btn-link {
  color: $text-color;
}
.panel-clear {
  box-shadow: none;
  background-color: transparent;
}
.table {
  th {
    text-transform: uppercase;
    font-family: 'KievitOT-Bold';
  }
  td {
    text-transform: capitalize
  }
}
.nav-tabs {
  display: inline-block;
  margin-bottom: -7px;
  border-color: transparent;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border-color: $brand-primary;
}
a {
  color: $text-color;
}

/* ==========================================================================
   genreal
========================================================================== */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  font-family: 'KievitOT-Regular';
  font-size: 16px;
  background: transparent url('../img/bg-app.jpg') top left no-repeat;
  background-size: cover;
  margin: 0;
  padding: 0;
}
.big-title {
  margin: 0 0 30px;

  .big-title-inner {
    display: inline-block;
  }
  &.center {
    .col-md-6 {
      text-align: center;
    }
    .big-title-text {
      color: $text-color;
      text-shadow: none;
    }
    .underline-red {
      width: 80% !important;
      margin-left: 10% !important;
    }
  }
  .big-title-text {
    float: left;
    text-transform: uppercase;
    color: #fff;
    text-shadow: 2px 2px 5px rgba(150, 150, 150, 1);
    margin: 10px 0;
    display: block;
  }
  .hot-corner {
    float: left;
    display: block;
    width: 20px;
    height: 20px;
    position: relative;

    &.top-right {
      .corner-horizontal {
        top: 0;
        left: 0;
        right: 0;
      }
      .corner-vertical {
        top: 0;
        right: 0;
      }
    }
    .corner {
      display: block;
      position: absolute;
      background-color: $brand-primary;
    }
    .corner-horizontal {
      width: 100%;
      height: 5px;
    }
    .corner-vertical {
      width: 5px;
      height: 100%;
    }
  }
  .underline {
    display: block;
    height: 2px;
    clear: both;

    &+.underline {
      margin-top: 5px;
    }
    &.underline-blue {
      width: 100%;
      background-color: $brand-secondary;
    }
    &.underline-red {
      width: 85%;
      margin-left: 15%;
      background-color: $brand-primary;
    }
  }
}
.subtitle-text {
  color: $text-color;
  text-transform: uppercase;
}
.view-title {
  margin: 0;

  .view-title-inner {
    display: inline-block
  }
  .view-title-text {
    text-transform: uppercase;
    color: $brand-secondary;
    margin: 0 10px;
    display: block;
    float: left;
  }
  .view-title-circle {
    display: block;
    float: left;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-top: 15px;
    background-color: $brand-primary;
  }
}
.table-filters {
  margin-bottom: 30px;
}

/* ==========================================================================
   sidemenu
========================================================================== */
#wrapper {
  padding-left: 0;
  transition: all 0.5s ease;

  &.toggled #sidebar-wrapper {
    left: 0;
    border-right: 2px solid $brand-secondary;
  }
}
#sidebar-wrapper {
  z-index: 998;
  left: -100%;
  width: 40%;
  max-width: 400px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff0107+0,ffffff+30,ffffff+100 */
  background: #ff0107; /* Old browsers */
  background: -moz-linear-gradient(top,  #ff0107 0%, #ffffff 30%, #ffffff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #ff0107 0%,#ffffff 30%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #ff0107 0%,#ffffff 30%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0107', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    position: fixed;

    @media(max-width: 767px) {
    width: 85%;
    }
  &::-webkit-scrollbar {
    display: none;
  }
}
#page-content-wrapper {
  position: relative;
}


/* ==========================================================================
   comment
========================================================================== */
.sidebar-nav {
    padding-left: 70px;
    padding-top: 50px;
    list-style: none;

  @media(max-width: 767px) {
      padding-left: 60px;
      padding-right: 30px;
  }
    @media(min-width: 1560px) {
      // margin-left: calc(50vw - 780px);
    }
    &.sidebar-nav-bottom {
      position: absolute;
      bottom: 0;
    }
    > .sidebar-brand {
      // height: 65px;
      font-size: 20px;
      line-height: 20px;

      span {
        display: block;
        text-align: center;
        padding: 10px 15px;
      }
  }
  .dropdown-menu {
      position: relative;
      width: 100%;
      padding: 0;
      margin: 0;
      border-radius: 0;
      border: none;
      background-color: #222;
      box-shadow: none;
  }
    li {
      position: relative;
      line-height: 20px;
      display: inline-block;
      width: 100%;

      &:first-child {
      padding-right: 50px;
      a {
          text-transform: uppercase;
        color: #fff;
        text-align: center;
        font-family: 'KievitOT-Bold';
        text-shadow: 2px 2px 5px rgba(150, 150, 150, 1);
          // background-color: #1a1a1a;
      }
      }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        height: 100%;
        width: 3px;
        background-color: $brand-secondary;
        -webkit-transition: width .2s ease-in;
          -moz-transition:  width .2s ease-in;
           -ms-transition:  width .2s ease-in;
                transition: width .2s ease-in;

    }
    &:first-child:before {
        background-color: transparent;
    }
    &:hover:before,
    &.open:hover:before {
        width: 100%;
        -webkit-transition: width .2s ease-in;
          -moz-transition:  width .2s ease-in;
           -ms-transition:  width .2s ease-in;
                transition: width .2s ease-in;

    }
    a {
        display: block;
        color: $brand-secondary;
        text-decoration: none;
        font-size: 20px;
        padding: 10px 15px 10px 30px;
    }
    a:hover,
    a:active,
    a:focus,
    &.open a:hover,
    &.open a:active,
    &.open a:focus {
        color: #fff;
        text-decoration: none;
        background-color: transparent;
    }
    }
}

/*-------------------------------*/
/*       Hamburger-Cross         */
/*-------------------------------*/

.hamburger {
  position: fixed;
  top: 20px;
  z-index: 999;
  display: block;
  width: 32px;
  height: 32px;
  margin-left: 15px;
  background: transparent;
  border: none;
}
.hamburger:hover,
.hamburger:focus,
.hamburger:active {
  outline: none;
}
.hamburger.is-closed:before {
  content: '';
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate3d(0,0,0);
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-closed:hover:before {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(-100px,0,0);
  -webkit-transition: all .35s ease-in-out;
}

.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom,
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  position: absolute;
  left: 0;
  height: 4px;
  width: 100%;
}
.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom {
  background-color: #1a1a1a;
}
.hamburger.is-closed .hamb-top {
  top: 5px;
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-closed .hamb-middle {
  top: 50%;
  margin-top: -2px;
}
.hamburger.is-closed .hamb-bottom {
  bottom: 5px;
  -webkit-transition: all .35s ease-in-out;
}

.hamburger.is-closed:hover .hamb-top {
  top: 0;
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-closed:hover .hamb-bottom {
  bottom: 0;
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  background-color: #1a1a1a;
}
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-bottom {
  top: 50%;
  margin-top: -2px;
}
.hamburger.is-open .hamb-top {
  -webkit-transform: rotate(45deg);
  -webkit-transition: -webkit-transform .2s cubic-bezier(.73,1,.28,.08);
}
.hamburger.is-open .hamb-middle { display: none; }
.hamburger.is-open .hamb-bottom {
  -webkit-transform: rotate(-45deg);
  -webkit-transition: -webkit-transform .2s cubic-bezier(.73,1,.28,.08);
}
.hamburger.is-open:before {
  content: '';
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate3d(0,0,0);
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-open:hover:before {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(-100px,0,0);
  -webkit-transition: all .35s ease-in-out;
}

/*-------------------------------*/
/*            Overlay            */
/*-------------------------------*/

.overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(250,250,250,.8);
    z-index: 90;
}

/*-------------------------------*/
/*           App Header          */
/*-------------------------------*/

.app-header-wrap {
  position: fixed;
  z-index: 50;
  width: 100%;
  max-width: 1560px;
  -webkit-transition: top 0.6s;
    -moz-transition: top 0.6s;
    transition: top 0.6s;

  &.off-canvas {
    top: -80px;
  }
  &.fixed {
    top: 0px;
  }
  .app-header {
    height: 80px;
    width: 100%;
    padding-right: 20px;
    z-index: 997;
    padding-left: 145px;
    position: relative;
  }
}

/*-------------------------------*/
/*         Main Container        */
/*-------------------------------*/

#main-content {
  padding-top: 80px;
}


/* ==========================================================================
   login
========================================================================== */
.bg-login {
  background: transparent url('../img/bg-login.jpg') top left no-repeat;
  background-size: contain;
  margin: 0;
  padding: 0;

  .panel {
    margin-top: 100px;
  }
}

/* ==========================================================================
   tables
========================================================================== */
.table-title {
  text-align: center;
  font-family: 'KievitOT-Bold';
  background-color: $brand-primary;
  height: 40px;
  border-radius: 20px 20px 0px 0px;
  -moz-border-radius: 20px 20px 0px 0px;
  -webkit-border-radius: 20px 20px 0px 0px;

  span {
    display: inline-block;
    text-transform: uppercase;
    color: #fff;
    font-size: 18px;
    padding: 5px 0;
  }
}